import React from "react";
import {
  IonRouterOutlet,
} from "@ionic/react";
import Home from "../pages/Home";
import {Redirect, Route} from "react-router";
import {IonReactRouter} from "@ionic/react-router";
import Projects from "../pages/Projects";
import About from "../pages/About";
import Plinko from "../pages/plinko/Plinko";

const Router: React.FC = () => {

  return(
    <IonReactRouter>
            <IonReactRouter>
            <Route exact path="/home" component={Home} />
            <Route exact path="/projects" component={Projects} />
          <Route exact path="/about" component={About} />
      <Redirect exact from="/" to="/home" />
            </IonReactRouter>
      {
        window.location.hostname.split('.')[0] === 'plinko' &&
        <IonReactRouter>
          <Route path="/" component={Plinko} />
          <Redirect exact from="*" to="/" />
        </IonReactRouter>
      }
    </IonReactRouter>
  )
}


export default Router;
