import {
  IonAccordion, IonAccordionGroup,
  IonButton,
  IonCol,
  IonContent, IonGrid,
  IonIcon, IonItem, IonLabel,
  IonPage, IonRow, IonText, IonThumbnail, useIonRouter
} from '@ionic/react';
import '../components/header/Header.css';
import React from "react";
import Header from "../components/header/Header";
import {arrowForward} from "ionicons/icons";
import trlogic from "../assets/logo/companies/trlogic.png";
import izu from "../assets/logo/companies/izu.png";



const About: React.FC = () => {
  const navigation = useIonRouter();

  return (
    <>
      <IonPage id="main-content">
        <Header/>
        <IonContent
          className="sm:ion-padding ion-align-items-center ion-justify-content-center ion-text-center w-full h-[80%] ">
          <IonGrid className="mb-20 sm:mb-0  mt-14 sm:mt-[5%] sm:mx-2 md:mx-4 lg:mx-6">
            <IonText className="flex ml-4 text-start font-medium text-3xl  md:leading-[1.1] md:text-5xl mb-10">About Me</IonText>
            <IonRow>
              <IonCol size="12" sizeMd="6">
                <IonLabel className="flex mx-4  lg:mx-6">
                  Work Experience
                </IonLabel>
                <IonAccordionGroup expand="inset">
                  <IonAccordion value="trlogic">
                    <IonItem slot="header" color="light" >
                      <div
                        className="text-5xl h-24  sm:!text-5xl sm:!h-24  lg:!text-6xl lg:!h-24 xl:!text-7xl xl:!h-28 ion-no-padding flex items-center">
                        <IonThumbnail>
                          <img
                            alt="Trlogic Logo"
                            src={trlogic} className="rounded-sm"/>
                        </IonThumbnail>
                      </div>
                      <IonLabel className="ml-5">
                        <IonLabel className="font-bold">
                          Frontend Developer
                        </IonLabel>
                      <IonLabel className="mt-2 font-normal">
                        Trlogic
                      </IonLabel>
                        <IonLabel>
                          Mar 2021 - Present
                        </IonLabel>
                      </IonLabel>
                    </IonItem>
                    <div className="ion-padding" slot="content" >
                      <IonItem style={{"--background": "unset"}}>
                        <IonLabel className="font-bold">
                          Part-time
                          <IonLabel className="font-normal">
                            Oct 2022 - Present
                          </IonLabel>
                        </IonLabel>
                      </IonItem>
                      <IonItem style={{"--background": "unset"}}>
                        <IonLabel className="font-bold">
                          Full-time
                          <IonLabel className="font-normal">
                            Jun 2022 - Oct 2022
                          </IonLabel>
                        </IonLabel>
                      </IonItem>
                      <IonItem style={{"--background": "unset"}}>
                        <IonLabel className="font-bold">
                          Part-time
                          <IonLabel className="font-normal">
                            Jun 2021 - Jun 2022
                          </IonLabel>
                        </IonLabel>
                      </IonItem>
                      <IonItem style={{"--background": "unset"}}>
                        <IonLabel className="font-bold">
                          Intern
                          <IonLabel className="font-normal">
                            Mar 2021 - Jun 2021
                          </IonLabel>
                        </IonLabel>
                      </IonItem>
                    </div>
                  </IonAccordion>
                </IonAccordionGroup>
              </IonCol>
              <IonCol size="12"  sizeMd="6">
                <IonLabel className="md:justify-end flex mx-4 lg:mx-6">
                  Education
                </IonLabel>
                <IonAccordionGroup expand="inset">
                  <IonAccordion value="trlogic" readonly toggleIcon=" ">
                    <IonItem slot="header" color="light">
                      <div
                        className="text-5xl h-24  sm:!text-5xl sm:!h-24  lg:!text-6xl lg:!h-24 xl:!text-7xl xl:!h-28 ion-no-padding flex items-center">
                        <IonThumbnail>
                          <img
                            alt="Istanbul Sabahattin Zaim University Logo"
                            src={izu}
                            className="rounded-sm"/>
                        </IonThumbnail>
                      </div>
                      <IonLabel className="ml-5">
                        <IonLabel className="font-bold">
                          Bachelor, Software Engineering
                        </IonLabel>
                        <IonLabel className="mt-2 font-normal">
                          Istanbul Sabahattin Zaim University
                        </IonLabel>
                        <IonLabel>
                          2020 - Present
                        </IonLabel>
                      </IonLabel>
                    </IonItem>
                  </IonAccordion>
                </IonAccordionGroup>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonText className="text-start">
                <IonButton size="large" fill="clear" color="dark" mode="ios"
                           className="rounded-br-sm"
                           routerLink="/projects">
                  <IonCol className="space-x-2 flex items-center">
                    <IonLabel> Lets Continue To Projects </IonLabel>
                    <IonIcon icon={arrowForward}/>
                  </IonCol>
                </IonButton>
              </IonText>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default About;
