import React from "react";
import "./MarqueeLogoWallStyle.css";
import {IonButton} from "@ionic/react";
import sleepwell from "../../assets/logo/sleepwell.svg";
import printedworks from "../../assets/logo/printedworks.svg";

const MarqueeLogoWallComponent = (props: { className?: any; }): JSX.Element => {

  return (
    <React.Fragment>
  <article className="wrapper">
    <div className="marquee">
      <div className="marquee__group">
        <IonButton href="https://play.google.com/store/apps/details?id=com.alperengozum.sleepwell" mode="ios" color="white" className="text-5xl h-24 sm:!text-3xl sm:!h-20 lg:!text-5xl lg:!h-24 xl:!text-6xl 2xl:!h-26 2xl:!text-7xl 2xl:!h-28  ion-no-padding" >
          <img
            alt="Sleepwell"
            src={sleepwell}
            className="h-20 sm:!h-20 lg:!h-24 2xl:!h-24 w-20 sm:!w-20 lg:!w-24 2xl:!w-24 rounded-full"/>
        </IonButton>
        <IonButton href="https://play.google.com/store/apps/details?id=com.printedworks" mode="ios" color="white" className="text-5xl h-24 sm:!text-3xl sm:!h-20 lg:!text-5xl lg:!h-24 xl:!text-6xl 2xl:!h-26 2xl:!text-7xl 2xl:!h-28  ion-no-padding" >
          <img
            alt="Printedworks"
            src={printedworks}
            className="h-20 sm:!h-20 lg:!h-24 2xl:!h-24 w-20 sm:!w-20 lg:!w-24 2xl:!w-24 rounded-full"/>
        </IonButton>
      </div>
      <div aria-hidden="true" className="marquee__group">
        <IonButton href="https://play.google.com/store/apps/details?id=com.alperengozum.sleepwell" mode="ios" color="white" className="text-5xl h-24 sm:!text-3xl sm:!h-20 lg:!text-5xl lg:!h-24 xl:!text-6xl 2xl:!h-26 2xl:!text-7xl 2xl:!h-28  ion-no-padding" >
          <img
            alt="Sleepwell"
            src={sleepwell
          } className="h-20 sm:!h-20 lg:!h-24 2xl:!h-24 w-20 sm:!w-20 lg:!w-24 2xl:!w-24 rounded-full"/>
        </IonButton>
        <IonButton href="https://play.google.com/store/apps/details?id=com.printedworks" mode="ios" color="white" className="text-5xl h-24 sm:!text-3xl sm:!h-20 lg:!text-5xl lg:!h-24 xl:!text-6xl 2xl:!h-26 2xl:!text-7xl 2xl:!h-28  ion-no-padding" >
          <img
            alt="Printedworks"
            src={printedworks}
            className="h-20 sm:!h-20 lg:!h-24 2xl:!h-24 w-20 sm:!w-20 lg:!w-24 2xl:!w-24 rounded-full"/>
        </IonButton>
      </div>
    </div>
  </article>

    </React.Fragment>
  )
}

export default MarqueeLogoWallComponent;
