import React from "react";


const Divider = (props: { className?: any; }): JSX.Element => {
  return(
    <div className={`dark:bg-white bg-black h-0.5 my-5 mx-5 ${props.className ? props.className : ""}`}/>
  )
}

export default Divider;
