import {
  IonButton,
  IonButtons, IonCol,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import './Header.css';
import React from "react";

const Header: React.FC = () => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonGrid>
          <IonRow className="place-content-between">
            <IonCol sizeSm="6" sizeXs="12">
              <IonButton mode="ios" fill="clear" expand="block" color="dark" className="min-h-[50px] text-xl lowercase font-light tracking-widest sm:text-2xl md:leading-[3rem] md:text-[36px] md:min-h-[80px]" >
              <IonTitle
                className="ion-no-padding min-h-[50px] text-xl sm:text-start lowercase font-light  tracking-widest sm:text-2xl md:leading-[3rem] md:text-[36px] md:min-h-[80px] ">alperengozum</IonTitle>
              </IonButton>
            </IonCol>
            <IonCol sizeSm="6" sizeXs="12">
              <IonButtons className="align-middle h-[100%] place-content-between">
                <IonButton className="w-1/3" routerLink="/home">Home</IonButton>
                <IonButton className="w-1/3" routerLink="/projects">Projects</IonButton>
                <IonButton className="w-1/3" routerLink="/about">About</IonButton>
              </IonButtons>
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
