import {
	IonAccordion,
	IonAccordionGroup,
	IonButton,
	IonCol,
	IonContent, IonGrid,
	IonIcon, IonItem, IonLabel,
	IonPage, IonRow, IonText,
} from '@ionic/react';
import '../../components/header/Header.css';
import React from "react";
import sleepwell from "../assets/logo/sleepwell.svg";
import {arrowForward, logoGithub, logoGooglePlaystore} from "ionicons/icons";
import Header from "../../components/header/Header";
//Sleepwell

const Plinko: React.FC = () => {
	return (
		<>
			<IonPage id="main-content">
				<Header/>
				<IonContent
					className="sm:ion-padding ion-align-items-center ion-justify-content-center ion-text-center w-full h-[100%] ">
					<IonGrid className="mb-20 sm:mb-0 sm:mt-[5%] md:mt-0 sm:mx-2 md:mx-4 lg:mx-6 h-full">
						<IonRow className="h-full">
							<IonCol>
								<IonText
									className="flex ml-4 text-start text-3xl  md:leading-[1.1] md:text-5xl mb-10 font-medium">Plinko</IonText>
							</IonCol>
							<IonCol className={"h-full w-[600px]"}>
								<iframe src="https://editor.p5js.org/Alperengozum/full/BOKwRyVKR" className={"w-[600px] h-full"}/>
							</IonCol>
						</IonRow>

					</IonGrid>
				</IonContent>
			</IonPage>
		</>
	);
};

export default Plinko;
