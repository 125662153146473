import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonCol,
  IonContent, IonGrid,
  IonIcon, IonItem, IonLabel,
  IonPage, IonRow, IonText,
} from '@ionic/react';
import '../components/header/Header.css';
import React from "react";
import Header from "../components/header/Header";
import sleepwell from "../assets/logo/sleepwell.svg";
import {arrowForward, logoGithub, logoGooglePlaystore} from "ionicons/icons";
import printedworks from "../assets/logo/printedworks.svg";
import "./Projects.css";
//Sleepwell
import react from "../assets/logo/techStackLogo/react.svg";
import nativebase from "../assets/logo/techStackLogo/nativebase.svg";
import reactnavigation from "../assets/logo/techStackLogo/reactnavigation.svg";
import admob from "../assets/logo/techStackLogo/admob.svg";
import figma from "../assets/logo/techStackLogo/figma.svg";
//Printedworks
import firebasenotification from "../assets/logo/techStackLogo/firebasenotification.svg";
import firebasedatabase from "../assets/logo/techStackLogo/firebasedatabase.svg";
import python from "../assets/logo/techStackLogo/python.svg";
import selenium from "../assets/logo/techStackLogo/selenium.svg";
import appcenter from "../assets/logo/techStackLogo/appcenter.svg";

const Projects: React.FC = () => {
  return (
    <>
      <IonPage id="main-content">
        <Header/>
        <IonContent
          className="sm:ion-padding ion-align-items-center ion-justify-content-center ion-text-center w-full h-[80%] ">
          <IonGrid className="mb-20 sm:mb-0  mt-14 sm:mt-[5%] sm:mx-2 md:mx-4 lg:mx-6">
            <IonText className="flex ml-4 text-start text-3xl  md:leading-[1.1] md:text-5xl mb-10 font-medium">All Projects</IonText>
            <IonRow>
              <IonAccordionGroup className="w-full" expand="inset">
                <IonAccordion value="sleepwell">
                  <IonItem slot="header" color="light">
                    <div
                      className="text-5xl h-24  sm:!text-5xl sm:!h-24  lg:!text-6xl lg:!h-24  xl:!text-7xl xl:!h-28 ion-no-padding flex items-center">
                      <img
                        alt="Sleepwell"
                        src={sleepwell}
                        className="h-12 sm:!h-12 lg:!h-16 xl:!h-20 w-12 sm:!w-12 lg:!w-16 xl:!w-20 rounded-full"/>
                    </div>
                    <IonLabel className="ml-5">Sleepwell</IonLabel>
                  </IonItem>
                  <div className="ion-padding" slot="content">
                    <IonText className="flex justify-center font-medium text-xl">
                      Introduction
                    </IonText>
                    Sleepwell is an open-source sleep cycle app that helps improve sleep by calculating and setting
                    alarms for optimal wake-up, bedtime, and power nap times.
                    Users can customize settings and track progress with a graph and sleep logs.
                    Issues and contributions are welcome on Github. Download Sleepwell for better sleep tonight.
                    <IonText className="mt-2 flex justify-center font-medium text-xl">
                      Tech Stack
                    </IonText>
                    <IonCol className="text-[26px] space-x-2">
                      <IonIcon icon={react}/>
                      <IonIcon icon={nativebase}/>
                      <IonIcon icon={reactnavigation}/>
                      <IonIcon icon={admob}/>
                      <IonIcon icon={figma}/>
                    </IonCol>
                  </div>
                  <div slot="content">
                    <IonGrid className="ion-no-padding">
                      <IonCol className="justify-end">
                        <IonButton size="large" fill="outline" color="dark"
                                   style={{paddingInline: 0, marginInline: 0, "--border-radius": 0}}
                                   className="w-[50%] rounded-0 justify-start"
                                   href="https://play.google.com/store/apps/details?id=com.alperengozum.sleepwell">
                          <IonCol className="justify-between flex items-center">
                            <IonLabel>Play Store </IonLabel>
                          <IonIcon icon={logoGooglePlaystore} className="invisible sm:visible sm:text-[26px]" />
                          </IonCol>
                        </IonButton>
                        <IonButton size="large" fill="outline" color="dark"
                                   style={{paddingInline: 0, marginInline: 0, "--border-radius": 0, borderBottomRightRadius: 10}}
                                   className="w-[50%] rounded-br-sm"
                                   href="https://github.com/Alperengozum/Sleepwell">
                          <IonCol className="justify-between flex items-center">
                            <IonIcon icon={logoGithub} className="text-[26px]" />
                            <IonLabel> Github </IonLabel>
                          </IonCol>
                        </IonButton>
                      </IonCol>
                    </IonGrid>
                  </div>
                </IonAccordion>
                <IonAccordion value="printedworks">
                  <IonItem slot="header" color="light">
                    <div
                      className="text-5xl h-24  sm:!text-5xl sm:!h-24  lg:!text-6xl lg:!h-24 xl:!text-7xl xl:!h-28 ion-no-padding flex items-center">
                      <img
                        alt="Printedworks"
                        src={printedworks}
                        className="h-12 sm:!h-12 lg:!h-16 xl:!h-20 w-12 sm:!w-12 lg:!w-16 xl:!w-20 rounded-full"/>
                    </div>
                    <IonLabel className="ml-5">Printed Works</IonLabel>
                  </IonItem>
                  <div className="ion-padding" slot="content">
                    <IonText className="flex justify-center font-medium text-xl">
                      Introduction
                    </IonText>
                    Printed Works is an app that offers over 12,000 pieces of curated content related to 3D printing,
                    including works, images, videos, and posts.
                    It features daily updates, notifications and includes selected authors, Thingiverse's best objects,
                    Reddit's best posts and videos, Youtube's best videos, and more, all in one place.
                    You can also save posts and hide authors you don't like.
                    <IonText className="flex mt-2 justify-center font-medium text-xl">
                      Tech Stack
                    </IonText>
                    <IonCol className="flex text-[26px] space-x-2 justify-center ">
                      <IonIcon icon={react}/>
                      <IonIcon icon={nativebase}/>
                      <IonIcon icon={firebasenotification}/>
                      <IonIcon icon={firebasedatabase}/>
                      <IonIcon icon={python}/>
                      <IonIcon icon={selenium}/>
                      <IonIcon icon={admob}/>
                      <IonIcon icon={appcenter}/>
                    </IonCol>
                    <IonGrid className="ion-no-padding">
                      <IonCol className="justify-end">
                        <IonButton size="large" fill="outline" color="dark"
                                   style={{paddingInline: 0, marginInline: 0, "--border-radius": 0}}
                                   className="w-[70%] sm:w-[50%] rounded-0 justify-start"
                                   href="https://play.google.com/store/apps/details?id=com.printedworks" >
                          <IonCol className="justify-between flex items-center">
                            <IonLabel>Play Store </IonLabel>
                            <IonIcon icon={logoGooglePlaystore} className="visible sm:text-[26px]" />
                          </IonCol>
                        </IonButton>
                      </IonCol>
                    </IonGrid>
                  </div>
                </IonAccordion>
              </IonAccordionGroup>
            </IonRow>
            <IonRow>
              <IonText className="text-start">
                <IonButton size="large" fill="clear" color="dark" mode="ios"
                           className="rounded-br-sm"
                           routerLink="/about">
                  <IonCol className="space-x-2 flex items-center">
                    <IonLabel> Lets Go To About Me</IonLabel>
                    <IonIcon icon={arrowForward}/>
                  </IonCol>
                </IonButton>
              </IonText>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Projects;
