import {
  IonButton,
  IonButtons, IonCol,
  IonContent, IonGrid,
  IonIcon,
  IonPage, IonRow, IonText,
  IonTitle,
  useIonRouter
} from '@ionic/react';
import '../components/header/Header.css';
import React from "react";
import Header from "../components/header/Header";
import Divider from "../components/divider/Divider";
import {logoGithub, logoGooglePlaystore, logoInstagram, logoLinkedin} from "ionicons/icons";
import spotify from "../assets/logo/spotify.svg"
import MarqueeLogoWallComponent from '../components/logoWall/MarqueeLogoWallComponent';

const Home: React.FC = () => {
  const navigation = useIonRouter();

  return (
    <>
      <IonPage id="main-content">
        <Header/>
        <IonContent
          className="sm:ion-padding ion-align-items-center ion-justify-content-center ion-text-center w-full h-[80%] ">
          <IonGrid className="sm:mt-[10%]">
            <IonRow>
              <IonCol size="7.5" sizeXs="12" sizeLg="6.5" sizeXl="7.5">
                <IonGrid
                  className="ion-padding ion-align-items-center ion-justify-content-center ion-text-center  w-full">
                  <IonRow className="ion-text-left">
                    <IonCol size="12">
                      <IonText className="p-4 font-medium mb-4 text-3xl md:text-4xl lg:text-4xl xl:text-5xl 2xl:text-6xl">
                        Hello!
                      </IonText>
                      <IonText className="ion-text-wrap font-medium text-3xl md:text-4xl lg:text-4xl xl:text-5xl 2xl:text-6xl">
                        <div className="p-4 ion-text-wrap">
                          I'm Alperen Gözüm
                        </div>
                      </IonText>
                      <Divider className="!mb-0 "/>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" className="place-content-between align-middle">
                      <IonButtons className="flex-col sm:flex-row  ion-no-padding sm:!h-20 lg:!h-24 2xl:!h-28 place-content-between">
                        <IonButton  href="https://play.google.com/store/apps/dev?id=8842825248111634874" mode="ios" color="white" className="text-5xl h-24  sm:!text-3xl sm:!h-20  lg:!text-5xl lg:!h-24 xl:!text-6xl 2xl:!h-26 2xl:!text-7xl 2xl:!h-28 ion-no-padding" >
                          <IonIcon icon={logoGooglePlaystore}/>
                        </IonButton>
                        <IonButton href="https://github.com/Alperengozum" mode="ios" color="white" className="text-5xl h-24 sm:!text-3xl sm:!h-20 lg:!text-5xl lg:!h-24 xl:!text-6xl 2xl:!h-26 2xl:!text-7xl 2xl:!h-28  ion-no-padding" >
                          <IonIcon icon={logoGithub}/>
                        </IonButton>
                        <IonButton href="https://www.instagram.com/alperengozum/" mode="ios" color="white" className="text-5xl h-24 sm:!text-3xl sm:!h-20 lg:!text-5xl lg:!h-24 xl:!text-6xl 2xl:!h-26 2xl:!text-7xl 2xl:!h-28  ion-no-padding" >
                          <IonIcon icon={logoInstagram}/>
                        </IonButton>
                        <IonButton href="https://www.linkedin.com/in/alperen-g%C3%B6z%C3%BCm-0868851b2/" mode="ios" color="white" className="text-5xl h-24 sm:!text-3xl sm:!h-20 lg:!text-5xl lg:!h-24 xl:!text-6xl 2xl:!h-26 2xl:!text-7xl 2xl:!h-28  ion-no-padding" >
                          <IonIcon icon={logoLinkedin}/>
                        </IonButton>
                        <IonButton href="https://open.spotify.com/user/21chlxxmx52sfhcu4ai56sw2a" mode="ios" color="white" className="text-5xl h-24 sm:!text-3xl sm:!h-20 lg:!text-5xl lg:!h-24 xl:!text-6xl 2xl:!h-26 2xl:!text-7xl 2xl:!h-28  ion-no-padding" >
                          <IonIcon icon={spotify}/>
                        </IonButton>
                      </IonButtons>
                    </IonCol>
                    <IonCol>
                      <Divider className="!mt-0"/>
                    </IonCol>
                    <IonCol size="12">
                      <MarqueeLogoWallComponent/>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol size="4" sizeXs="12" sizeLg="5" sizeXl="4">
                <IonGrid className="mb-20 sm:mb-0 ion-padding ion-align-items-center ion-justify-content-center ion-text-center w-full">
                  <IonRow className="mt-0 sm:mt-[12vh] lg:mt-0 md:space-y-[2vh]  lg:space-y-[10vh]">
                    <IonCol size="12" sizeMd="12">
                      <IonButton  mode="ios" fill="clear" expand="full" color="dark" className="normal-case" routerLink="/home">
                        <IonTitle className=" font-light underline underline-offset-4 text-slate-500 sm:text-lg  md:text-2xl  lg:text-3xl xl:text-4xl">
                          <div className="ion-text-nowrap">
                            Home
                          </div>
                        </IonTitle>
                      </IonButton>
                    </IonCol>
                    <IonCol size="12" sizeMd="12">
                      <IonButton mode="ios" fill="clear" expand="full" color="dark" className="normal-case" routerLink="/projects">
                        <IonTitle className="font-light !overflow-clip sm:text-lg md:text-xl  lg:text-3xl xl:text-4xl">
                          <div className="ion-text-nowrap">
                            Projects
                          </div>
                        </IonTitle>
                      </IonButton>
                    </IonCol>
                    <IonCol size="12" sizeMd="12">
                      <IonButton mode="ios" fill="clear" expand="full" color="dark" className="normal-case" routerLink="/about">
                        <IonTitle className="font-light sm:text-lg md:text-xl  lg:text-3xl xl:text-4xl">
                          <div className="ion-text-nowrap">
                            About
                          </div>
                        </IonTitle>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Home;
